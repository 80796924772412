// extracted by mini-css-extract-plugin
export var answer = "CubeWillOutliveMagicPlaymatPage-module--answer--c8a80";
export var container = "CubeWillOutliveMagicPlaymatPage-module--container--c362a";
export var description = "CubeWillOutliveMagicPlaymatPage-module--description--722e2";
export var details = "CubeWillOutliveMagicPlaymatPage-module--details--d64cf";
export var faq = "CubeWillOutliveMagicPlaymatPage-module--faq--48326";
export var image = "CubeWillOutliveMagicPlaymatPage-module--image--f4fef";
export var info = "CubeWillOutliveMagicPlaymatPage-module--info--82173";
export var inlineLink = "CubeWillOutliveMagicPlaymatPage-module--inlineLink--d7907";
export var label = "CubeWillOutliveMagicPlaymatPage-module--label--dee20";
export var labelValue = "CubeWillOutliveMagicPlaymatPage-module--labelValue--9fd79";
export var purchaseRow = "CubeWillOutliveMagicPlaymatPage-module--purchaseRow--2288b";
export var question = "CubeWillOutliveMagicPlaymatPage-module--question--da983";
export var soldOut = "CubeWillOutliveMagicPlaymatPage-module--soldOut--25c1d";
export var title = "CubeWillOutliveMagicPlaymatPage-module--title--2aa9e";