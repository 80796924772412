// title: Lucky Paper Shop
// frameCardName: Rishadan Pawnshop
// frameCardSet: MMQ
// lastUpdatedDate: 2024-03-15

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import CubeWillOutliveMagicPlaymatPage from 'src/components/shop/CubeWillOutliveMagicPlaymatPage'

interface Props {
  pageContext: any
}

const ShopPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#0562F3"
      includeNewsletterSignup
    >
      <CubeWillOutliveMagicPlaymatPage />
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Cube will outlive Magic playmat — Lucky Paper Shop',
        description: 'Custom gold-foiled playmat celebrating Cube.',
        ogImageURL:
          'https://luckypaper.co/og-images/cube-will-outlive-magic-playmat.jpg',
      }}
    />
  )
}

export default ShopPage
